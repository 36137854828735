@use 'sass:math';

$spacing-none: 0;
$spacing-xxs: 0.25rem;
$spacing-xs: 0.5rem;
$spacing-s: 1rem;
$spacing-m: 1.5rem;
$spacing-l: 2rem;
$spacing-xl: 3rem;
$spacing-xxl: 4rem;

$xxs: $spacing-xxs;
$xs: $spacing-xs;
$s: $spacing-s;
$m: $spacing-m;
$l: $spacing-l;
$xl: $spacing-xl;
$xxl: $spacing-xxl;

$sizes: (
  extra-small: $xs,
  small: $s,
  medium: $m,
  large: $l,
  extra-large: $xl,
  extra-extra-large: $xxl,
);

$border-radius: 5px;

$base-font-size: 16;
@function rem($pixel-size) {
  @return #{math.div($pixel-size, $base-font-size)}rem;
}
