@use '_color' as *;
@use '_space' as *;
@use 'sass:map';

@mixin focus-ring-focus($ring-color: #bedeef, $focus-border-color: #247baf) {
  outline: 0;
  box-shadow: 0 0 0px 4px $ring-color;
  border: 1px solid $focus-border-color;
}

@mixin focus-ring(
  $ring-color: #bedeef,
  $focus-border-color: #247baf,
  $normal-border-color: transparent
) {
  border: 1px solid $normal-border-color;

  &:focus {
    @include focus-ring-focus($ring-color, $focus-border-color);
  }
}

@mixin no-autofill-styles($color: 'initial') {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $color !important;
  }
}

@mixin visually-hidden {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  border: 0;
  top: 0; // This is necessary for it to be taken out of the page's flow, so it doesn't continue to take up space
  white-space: nowrap;
  word-wrap: normal;
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin remove-focus-state {
  box-shadow: none;
  border: 0;
}

@mixin mouse-focus {
  [data-whatintent='touch'] &,
  [data-whatintent='mouse'] & {
    &:active,
    &:focus {
      @content;
    }
  }
}

// Allows you to use all the available colours, defined in props
// Add this inside the class you want to extend with colour options, e.g.
// .icon {
//     @include color-options("background");
// }
// then you can add it via classnames in your component:
// className={cx({[styles[`icon-${String(color)}`]]: true })}
@mixin color-options($property) {
  @each $color, $variable in $colors {
    &-#{'' + $color} {
      #{$property}: $variable;
    }
  }
}

// Allows you to use all the available text colour variables
// Add this inside the class you want to extend with colour options, e.g.
// .icon {
//     @include text-color-variables();
// }
$textColorVariables: 'primary', 'secondary', 'inverted', 'disabled',
  'destructive', 'positive', 'warning', 'action';
@mixin text-color-variables() {
  @each $color in $textColorVariables {
    &-#{'' + $color} {
      color: var(--text-color-#{$color}, currentColor);
    }
  }
}

// Allows you to use all the available icon fill/background colour variables
// Add this inside the class you want to extend with colour options, e.g.
// .icon {
//     @include fill-color-variables();
// }
$fillColorVariables: 'primary', 'secondary', 'negative', 'warning', 'positive',
  'neutral';
@mixin fill-color-variables() {
  @each $color in $fillColorVariables {
    &-#{'' + $color} {
      background-color: var(--fill-#{$color}, currentColor);
    }
  }
}

// Allows you to use all the available sizes, with the properties you want to apply it to defined in props
// Add this inside the class you want to extend with colour options, e.g.
// .icon {
//      @include size-options('min-width', 'min-height', 'width', 'height');
// }
// then you can add it via classnames in your component:
// className={cx({[styles[`icon-${String(size)}`]]: true })}
@mixin size-options($properties...) {
  @each $size, $variable in $sizes {
    &-#{'' + $size} {
      @each $property in $properties {
        #{$property}: $variable;
      }
    }
  }
}

// Use to easily include styles for a specific theme in your SCSS. For example:
// .container {
//   background-color: $color-white;
//   @include theme(light-refreshed) {
//     background-color: $color-soft-white;
//   }
// }
//
// This is best used when a style isn't commonly used enough to warrant a variable in the themes.scss file
$themes: (
  light: 'light',
  light-refreshed: 'light-refreshed',
  business: 'business-light',
) !default;

@mixin theme($themeName) {
  @if map.has-key($themes, $themeName) {
    :root[data-theme='#{$themeName}'] & {
      @content;
    }
  } @else {
    @warn "Theme #{$themeName} wasn't found in $themes map";
  }
}
