@use '../../tokens/globals' as *;
@use 'sass:color';

[data-whatintent='touch'] .input:not(:disabled):not(:read-only),
[data-whatintent='mouse'] .input:not(:disabled):not(:read-only) {
  &:active,
  &:focus,
  &:active:focus {
    box-shadow: none;
    border: 1px solid var(--input-border-focus, $color-sky500);
  }
}

.input {
  background-color: var(--input-background, $color-white);
  border: 1px solid var(--input-border, $color-grey400);
  border-radius: 6px;
  padding: 12px $s;
  font-family: var(--font-stack-body, $font-stack-maison);
  font-size: 1rem;
  font-weight: var(--input-font-weight, 400);

  &::placeholder {
    color: var(--input-placeholder-text, $color-grey400);
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  &:hover {
    border: 1px solid
      var(--input-border-hover, color.scale($color-grey500, $lightness: 10%));
  }

  &:not(:disabled) {
    &:focus &:active {
      border: 1px solid var(--input-border-focus, $color-sky500);
      // stops the hover from activating when input already active
      &:hover {
        border: 1px solid var(--input-border-focus, $color-sky500);
      }
    }
  }

  &:disabled {
    color: var(--input-text-disabled);
    border: 1px solid var(--input-border-disabled, $color-grey400);
    background-color: var(--input-background-disabled, $color-grey200);
    &:hover {
      cursor: not-allowed;
    }
    &::placeholder {
      color: var(--input-text-disabled);
    }
  }

  &:read-only {
    color: var(--text-color-primary);
    border: 1px solid transparent;
    background-color: var(--background-primary, $color-grey200);

    &:hover {
      cursor: default;
    }
  }

  // Is support good enough to use the native :invalid?
  &:not(:focus).invalid {
    border: 1px solid var(--input-border-invalid, $color-hot-coral);
    background: var(--input-background-invalid, rgba($color-hot-coral, 0.02));
  }

  &.full-width {
    width: 100%;
  }
}

.clear {
  align-items: center;
  display: inline-flex;
  position: relative;
  &.full-width {
    display: flex;
  }
  input {
    padding-right: $s * 2;
  }
  button {
    align-items: center;
    background: inherit;
    border: 0;
    cursor: pointer;
    display: flex;
    height: 100%;
    position: absolute;
    right: calc($s / 3);
  }
}

.small {
  padding: 6px calc($s / 2);
  font-size: 0.9rem;
}
