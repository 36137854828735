.loading-spinner {
  position: relative;
  animation: spinner 800ms linear infinite;
  @media (prefers-reduced-motion: reduce) {
    animation: spinner-reduced-motion 2500ms ease-in-out infinite;

    .ring {
      stroke-opacity: 0.3;
    }
    .blob {
      display: none;
    }
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-reduced-motion {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
