@use '../../tokens/globals' as *;
@use 'sass:color';

.text {
  letter-spacing: 0;
  line-height: 1.4;
  font-weight: var(--font-weight-normal, $font-weight-normal);
  font-family: var(--font-stack-body, $font-stack-maison);
  color: var(--text-color-primary, $color-grey800);
}

.color-primary {
  color: var(--text-color-primary, $color-grey800);
}

.color-secondary {
  color: var(--text-color-secondary, $color-grey600);
}

.color-inverted {
  color: var(--text-color-inverted, $color-grey300);
}

.color-disabled {
  color: var(--text-color-disabled, $color-grey400);
}

.color-destructive {
  color: var(
    --text-color-destructive,
    color.scale($color-hot-coral, $lightness: -25%)
  );
}

.color-warning {
  color: var(--text-color-warning, $color-tangerine500);
}

.color-positive {
  color: var(--text-color-positive, $color-grass500);
}

.color-action {
  color: var(--text-color-action, $color-sky500);
}

.color {
  @include color-options('color');
}

.color-currentColor {
  color: currentColor;
}

.bold {
  font-weight: var(--font-weight-bold, $font-weight-bold);
}

.size-extra-small {
  font-size: 0.625rem;
}

.size-small {
  font-size: 0.875rem;
}

.size-medium {
  font-size: 1rem;
}

.size-large {
  font-size: 1.25rem;
}

.size-extra-large {
  font-size: 1.5rem;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}
