@use '../../tokens/globals' as *;

.title {
  font-weight: var(--font-weight-bold, $font-weight-bold);
  font-family: var(--font-stack-title, $font-stack-title);
  color: var(--text-color-primary, $color-grey800);
}

.size-extra-small {
  font-size: 1.25rem;

  @media (min-width: 580px) {
    font-size: 1.5rem;
  }
}

.size-small {
  font-size: 1.5rem;

  @media (min-width: 580px) {
    font-size: 1.75rem;
  }
}

.size-medium {
  font-size: 1.75rem;

  @media (min-width: 580px) {
    font-size: 2.25rem;
  }
}

.size-large {
  font-size: 2rem;

  @media (min-width: 580px) {
    font-size: 2.75rem;
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.color {
  @include color-options('color');
  @include text-color-variables();
}

.color-currentColor {
  color: currentColor;
}
