@use '../../tokens/globals' as *;

.major {
  font-family: var(--font-stack-money, $font-stack-maison);
  font-size: 1em;
}

.minor {
  font-family: var(--font-stack-money, $font-stack-maison);
  font-size: 0.7em;
}

.symbol {
  font-family: var(--font-stack-money, $font-stack-maison);
  font-size: 1em;
}
