@use '../../tokens/globals' as *;

.dialog {
  width: 100%;
  max-width: rem(568);
  background-color: var(--background-primary, $color-white);
  box-shadow: var(--dialog-shadow, 0 1px 6px 0 rgba(0, 0, 0, 0.2));
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  // sass has its own min() function which can't use different units, but native CSS supports this
  max-height: unquote('min(80vh, 800px)');
  min-height: rem(612);
  position: relative;

  &.unclampHeight {
    max-height: none;
    min-height: initial;
    margin: auto;
  }

  &.background-primary {
    background: var(--background-primary, $color-white);
  }

  &.background-secondary {
    background: var(--background-secondary, $color-grey200);
  }

  &.background {
    @include color-options('background');
  }

  &:focus {
    outline: 0;
  }
}

.header {
  border-bottom: 1px solid var(--border-color-primary, $color-grey300);
  text-align: center;
  min-height: rem(88);
  padding: $spacing-m $spacing-s;

  &.no-border {
    border-bottom: 0;
  }

  @include mq($from: tablet) {
    min-height: rem(94);
    padding: 2.5 * $spacing-s $spacing-m $spacing-s;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.back {
  position: absolute;
  top: 10px;
  left: 10px;
}

.innerScrollWrapper {
  overflow: auto;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.footerActionsBar {
  background-color: var(--background-secondary, $color-grey200);
  border-top: 1px solid var(--border-color-primary, rgba(200, 199, 204, 0.3));
  padding: $spacing-s $spacing-m;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 6px 6px;

  .primary {
    background-color: var(--background-primary, $color-white);
  }

  button + button {
    margin-left: $spacing-l;
    position: relative;

    &:before {
      width: 1px;
      height: 100%;
      position: absolute;
      left: $spacing-s * -1;
      top: 0;
      content: '';
      display: block;
      background-color: var(--border-color-primary, $color-grey400);
    }

    &:focus:before {
      outline: 0;
    }
  }
}

.footerSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $spacing-xs $spacing-m $spacing-m;
  border-radius: 0 0 $border-radius $border-radius;
  background-color: var(--background-primary, none);

  // We have to explicitly set 'flex:none' or else Safari will sometimes break. Like this:
  // https://monzo.slack.com/archives/CUDG97T1A/p1613753656008400
  flex: none;

  &.withFooterBorder {
    padding-top: $spacing-m;
    border-top: 1px solid var(--border-color-primary, $color-grey300);
  }

  &.background-primary {
    background: var(--background-primary, $color-white);
  }

  &.background-secondary {
    background: var(--background-secondary, $color-grey200);
  }

  &.background {
    @include color-options('background');
  }
}

.leftAlignHeader {
  padding-left: 0;

  > h1,
  div {
    text-align: left;
  }
}
