@use './variables' as *;

* {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  touch-action: manipulation;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

h2 {
  line-height: 1.4em;
}

h3 {
  line-height: 1.4em;
}

p {
  line-height: 1.4em;
}

body {
  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;

  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  color: #14233c;
  font: 20px 'Maison Neue', 'HelveticaNeue', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
}

strong {
  font-weight: bold;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

a {
  font-weight: bold;
  text-decoration: none;
  color: $color-link;
}

input::placeholder {
  line-height: normal !important;
  color: $color-mid-text;
}

input[type='submit'] {
  appearance: none;
  -webkit-appearance: none;
}

#root {
  height: 100%;
}
