@use '../../tokens/globals' as *;
@use 'sass:color';

.button {
  font-size: 16px;
  font-weight: var(--font-weight-bold, $font-weight-bold);
  border-radius: 4px;
  padding: 12px $spacing-s;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &-loading {
    position: relative;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.full-width {
    width: 100%;
  }
}

.theme-primary {
  color: var(--primary-action-text, $color-white);
  background-color: var(--primary-action-bg, $color-sky500);
  font-weight: var(--font-weight-semi-bold, $font-weight-semi-bold);
  border: 1px solid var(--primary-action-border, transparent);

  &:not(:disabled):hover {
    background-color: var(--primary-action-bg-hover, #007cb4);
  }

  &:not(:disabled):active,
  &:not(:disabled).loading {
    background-color: var(--primary-action-bg-active, #006596);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--primary-action-bg-disabled, $color-sky400);
  }
}

.theme-secondary {
  background: var(--secondary-action-bg, $color-white);
  border: 1px solid var(--secondary-action-border, $color-grey400);
  color: var(--secondary-action-text, $color-grey600);

  &:not(:disabled):hover {
    background-color: var(--secondary-action-bg-hover, $color-grey200);
  }

  &:not(:disabled):active {
    background-color: var(
      --secondary-action-bg-active,
      color.scale($color-grey200, $lightness: -10%)
    );
  }

  &:disabled {
    background-color: var(--secondary-action-bg-disabled, $color-grey200);
    opacity: 0.8;
  }
}

.theme-invisible {
  background: none;
  padding: 0;
  border: 1px solid transparent;
}

.theme-tertiary {
  background-color: var(--tertiary-action-bg, $color-white);
  border: 1px solid var(--tertiary-action-border, $color-grey300);
  color: var(--tertiary-action-text, $color-grey600);
  border-radius: 22px;

  &:hover {
    border: 1px solid var(--tertiary-action-border-hover, $color-grey400);
    background-color: var(--tertiary-action-bg-hover, $color-grey200);
  }

  &:active {
    background: var(
      --tertiary-action-bg-active,
      color.scale($color-grey200, $lightness: -5%)
    );
    border: 1px solid var(--tertiary-action-border-active, $color-grey400);
  }

  &.loading {
    background-color: var(--tertiary-action-bg-hover, $color-grey200);
    border: 1px solid var(--tertiary-action-border-hover, $color-grey400);
  }

  &:disabled {
    opacity: 0.5;
  }

  &.selected {
    border: 1px solid var(--tertiary-action-border-selected, $color-sky500);
    background-color: var(
      --tertiary-action-bg-selected,
      rgba($color-sky500, 0.02)
    );
  }
}

.theme-destructive {
  background: var(--destructive-action-bg, $color-red500);
  border: 1px solid var(--destructive-action-border, transparent);
  color: var(--destructive-action-text, $color-white);

  &:disabled {
    background-color: var(--destructive-action-bg-disabled, $color-red400);
  }

  &:not(:disabled):hover {
    background-color: var(
      --destructive-action-bg-hover,
      color.scale($color-red500, $lightness: -10%)
    );
  }

  &:not(:disabled):active {
    background-color: var(
      --destructive-action-bg-active,
      color.scale($color-red500, $lightness: -10%)
    );
  }
}

.theme-affirmative {
  background: var(--affirmative-action-bg, $color-grass600);
  border: 1px solid var(--affirmative-action-border, transparent);
  color: var(--affirmative-action-text, $color-white);

  &:disabled {
    background-color: var(--affirmative-action-bg-disabled, $color-grass700);
    color: var(--affirmative-action-text-disabled, $color-grey3);
  }

  &:not(:disabled):hover {
    background-color: var(
      --affirmative-action-bg-hover,
      color.scale($color-grass600, $lightness: -10%)
    );
  }

  &:not(:disabled):active {
    background-color: var(
      --affirmative-action-bg-active,
      color.scale($color-grass700, $lightness: -10%)
    );
  }
}

.theme-dark {
  background: var(--dark-action-bg, $color-navy1);
  border: 1px solid var(--dark-action-border, $color-navy1);
  color: var(--dark-action-text, $color-white);
  border-radius: 100px;

  &:disabled {
    background-color: var(--dark-action-bg-disabled, $color-navy3);
  }

  &:not(:disabled):hover {
    background-color: var(
      --dark-action-bg-hover,
      color.scale($color-navy3, $lightness: -10%)
    );
  }

  &:not(:disabled):active {
    background-color: color.scale($color-navy4, $lightness: -10%);
  }
}

// for what-input compatibility
[data-whatintent='mouse'] .theme-secondary,
[data-whatintent='touch'] .theme-secondary {
  &:active,
  &:focus,
  &:active:focus {
    border: 1px solid var(--secondary-action-border, $color-grey400);
  }
}

// for what-input compatibility
[data-whatintent='mouse'] .theme-tertiary,
[data-whatintent='touch'] .theme-tertiary {
  &:active,
  &:focus,
  &:active:focus {
    border: 1px solid var(--tertiary-action-border-hover, $color-grey400);
  }
}

// for what-input compatibility
[data-whatintent='mouse'] .theme-destructive,
[data-whatintent='touch'] .theme-destructive {
  &:active,
  &:focus,
  &:active:focus {
    border: 1px solid var(--destructive-action-border, transparent);
  }
}

.icon {
  margin-right: $spacing-xs;
}

.spinner {
  position: absolute;
  height: 24px;
  width: 24px;
}

.content {
  visibility: visible;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hideContent {
    visibility: hidden;
  }
}

.lone-icon {
  width: 44px;
  height: 44px;
  padding: 0;
  border-radius: 50%;

  .icon {
    margin-right: 0;
  }
}

.icon-only-on-mobile {
  @include mq($until: tablet) {
    .icon {
      margin-right: 0;
    }

    .content {
      @include visually-hidden;
    }
  }
}

.size {
  &-small {
    font-size: rem(14);
    padding: 8px $spacing-s;
    font-weight: var(--font-weight-semi-bold);
  }

  &-regular {
    font-size: rem(16);
    padding: 12px $spacing-s;
    font-weight: var(--font-weight-bold);
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
