@use '../../tokens/globals' as *;

.root {
  flex-shrink: 0;
}

.color {
  @include color-options('color');
  @include text-color-variables();
}

.color-currentColor {
  color: currentColor;
}
