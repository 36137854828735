@use '../../tokens/globals' as *;

.card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--background-primary, $color-white);
  border: 1px solid var(--border-color-primary, $color-grey300);
  padding: $spacing-s * 1.25;
  box-shadow: var(
    --card-shadow,
    0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px rgba(17, 17, 26, 0.1)
  );

  &.secondary {
    background-color: var(--background-secondary, $color-grey200);
  }

  @include mq($from: tablet) {
    flex: 0 1 $spacing-xs * 40;
  }

  & + & {
    margin-top: $spacing-s;

    @include mq($from: tablet) {
      margin-top: 0;
      margin-left: $spacing-s;
    }
  }
}
