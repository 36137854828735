@use '../../tokens/globals' as *;

.root {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  vertical-align: text-bottom;
  margin: 0;

  &:not(.square) {
    border-radius: 50%;
  }

  &.square {
    border-radius: $border-radius;
  }
}

.initials {
  font-size: 0.8rem;
  font-weight: var(--font-weight-avatar, $font-weight-semi-bold);
}

.image {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 0;
  object-fit: cover;
}

.size-small {
  width: 24px;
  height: 24px;
}

.size-medium {
  width: 32px;
  height: 32px;
}

.size-large {
  width: 40px;
  height: 40px;

  .initials {
    font-size: 1rem;
  }
}

.size-extra-large {
  width: 64px;
  height: 64px;

  .initials {
    font-size: 1.5rem;
  }
}

.size-extra-large-2 {
  width: 80px;
  height: 80px;

  .initials {
    font-size: 1.5rem;
  }
}

.fallback-0 {
  color: var(--avatar-1-text, #f5b946);
  background: var(--avatar-1-bg, #fdf1da);
}

.fallback-1 {
  color: var(--avatar-2-text, #eb824a);
  background: var(--avatar-2-bg, #fbe6db);
}

.fallback-2 {
  color: var(--avatar-3-text, #e64b5f);
  background: var(--avatar-3-bg, #fadbdf);
}

.fallback-3 {
  color: var(--avatar-4-text, #f09696);
  background: var(--avatar-4-bg, #fceaea);
}

.fallback-4 {
  color: var(--avatar-5-text, #b982ff);
  background: var(--avatar-5-bg, #f1e6ff);
}

.fallback-5 {
  color: var(--avatar-6-text, #e1b981);
  background: var(--avatar-6-bg, #f9f1e6);
}

.fallback-6 {
  color: var(--avatar-7-text, #1e788c);
  background: var(--avatar-7-bg, #d2e4e8);
}

.fallback-7 {
  color: var(--avatar-8-text, #9abba7);
  background: var(--avatar-8-bg, #ebf1ed);
}
