@use '../../tokens/globals' as *;

.filled-icon {
  background-color: var(--fill-primary, $color-sky500);
  padding: 5px;
  border-radius: 5px;
}

.background {
  @include fill-color-variables();
}

.size {
  &-small {
    padding: 3px;
    border-radius: 3px;
  }
  &-medium {
    padding: 6px;
  }
  &-large {
    padding: 6px;
  }
}
